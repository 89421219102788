@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');
.atma {
  font-family: "Lexend", cursive;
  font-weight: 400;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');
.pixelfont {
  font-family: "VT323", monospace;
  font-weight: 400;
  font-style: normal;
}

.backgroundImage {
  background-image: url('./assets/banner.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.headertext {
  opactiy: 1;
  z-index: 1;
}